.main-toast{
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999999999;
  border: none;
  //background-color: #34C38F;
  &.bg-primary{
    .toast-body{
      color: #FFFFFF;
    }
  }
  &.bg-danger{
    .toast-body{
      color: #FFFFFF;
    }
  }
}