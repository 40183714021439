#invoice-modal{
  @media print {
    top: 0;
    align-items: flex-start;
    .title-modal{
      display: none;
    }
  }
  .logo{
    @media screen{
      display: none;
    }
  }
}