// 
// _pagination.scss
// 

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        
        border-radius: 30px !important;
        background-color: #34c38f4f;
        margin: 0 3px !important;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        text-align: center;
        line-height: 32px;
    }
  }

  .page-link {
    position: relative;
    display: block;
    color: $pagination-color;
    text-decoration: if($link-decoration == none, null, none);
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    @include transition($pagination-transition);
  
    &:hover {
      z-index: 2;
      color: $pagination-hover-color;
      text-decoration: if($link-hover-decoration == underline, none, null);
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  
    &:focus {
      z-index: 3;
      color: $pagination-focus-color;
      background-color: $pagination-focus-bg;
      outline: $pagination-focus-outline;
      box-shadow: $pagination-focus-box-shadow;
    }
  }
  
  .page-item {
    &:not(:first-child) .page-link {
      margin-left: $pagination-margin-start;
    }
  
    &.active .page-link {
      z-index: 3;
      color: $pagination-active-color;
      @include gradient-bg(#34C38F!important);
      border-color: $pagination-active-border-color;
    }
  
    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
  