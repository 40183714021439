@import "../../assets/scss/theme.scss";

#uv_payments{
  @media print{
    display: none;
  }
}
.row-buttons-container flex-nowrap{
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  button{
    margin-right: 10px;
  }
}