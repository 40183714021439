#main-loader{
  .main-loader-spinner-cont{
    display: none;
    position: fixed;
    z-index: 99999999999;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: #00000099;
    align-items: center;
    justify-content: center;
    .spinner-border-50, .spinner-border, .text-primary {
      height: 100px;
      width: 100px;
      border-width: 18px;
    }
  }
  &.active{
    .main-loader-spinner-cont{
      display: flex !important;
    }
  }
}