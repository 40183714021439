@import "../../assets/scss/theme.scss";

    .checkbox-style{

        [type="checkbox"] {
            display: none;
        }

        label{
            padding-left: 1.3em;
            position: relative;
            cursor: pointer;
        }

        label:before{
            content: ' ';
            border: solid 1px rgb(134, 134, 134);
            border-radius: 3px;
            width: 0.95em;
            height: 0.95em;
            position: absolute;
            left: 0;
            top: 4px;
        }

        [type="checkbox"]:checked + label:before {
            border-color: #34C38F;
            color: #34C38F;
            background-color: #34C38F;
            background-image: url(../../assets/images/cheque.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 8px;
        }

    }

    .rangeslider-horizontal{
        height: 5px !important;
    }

    // .rangeslider__handle{
    //     display: none !important;
    // }

    .rangeslider__handle{
            height: 12px !important;
            width: 12px !important;
            bottom: 0px !important;
            top: 2px !important;
            border: solid 1px !important;
            position: absolute !important;
            border-color: #1d7e5a !important;
            background-color:  #82e9c3!important;
            border-radius: 50% !important;
            color: #34C38F !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: 8px !important;
        }

    .rangeslider__handle:after{
        display: none;
    }   