.invoice{
  .header-row{
    align-items: center;
    .invoice-logo{
      height: 75px;
    }
  }
  .text-element {
    word-break: break-all;
  }
  .lot-data{
    height: 30px;
    @media screen and (max-width: 624px){
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .table-item{
      @media screen and (max-width: 624px){
        width: 100%;
      }
      p{
        margin: 0;
      }
    }
  }
  .row {
    @media screen and (max-width: 624px) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      &.lot-data{
        height: auto;
      }
      .table-item {
        width: 100%;
      }
    }
  }
}