#payments {

    .editbtn{
        background-color: transparent !important;
        width: 20px;
        height: 20px;
        background-image: url(../../../images/edit.png);
        background-repeat: no-repeat;
        background-size: cover;
        border: 0;
    }
    .deletebtn{
        background-color: transparent !important;
        width: 20px;
        height: 20px;
        background-image: url(../../../images/delete.png);
        background-repeat: no-repeat;
        background-size: cover;
        border: 0;
    }
    .modal { 
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        background: var(--black);
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        transition: all 0.35s ease-in;
      }
      .modal-dialog {
        position: relative;
        max-width: 800px;
        max-height: 80vh;
        border-radius: 5px;
        background: var(--white);
        overflow: auto;
        cursor: default;
      }
      .modal-dialog > * {
        padding: 1rem;
      }
       
      .modal-header,
      .modal-footer {
        background: var(--lightgray);
      }
       
      .modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
       
      .modal-header .modal-close {
        font-size: 1.5rem;
      }
       
      .modal p + p {
        margin-top: 1rem;
      }
      .modal {
        visibility: hidden;
        opacity: 0;
        transition: all 0.35s ease-in;
      }
       
      .modal.is-visible {
        visibility: visible;
        opacity: 1;
      }
}